import React, { Component } from "react"
import { graphql } from "gatsby"
import PageTransition from "gatsby-plugin-page-transitions"
import Layout from "../components/layout"
import SEO from "../components/seo"

export const query = graphql`
  {
    allSanityMusic(sort: { fields: [date], order: [DESC] }) {
      edges {
        node {
          title
          iframe
          date
        }
      }
    }
  }
`

export default class Music extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isReversed: false,
    }
  }

  reverseArray() {
    this.setState({
      isReversed: !this.state.isReversed,
    })
  }

  render() {
    const { data } = this.props
    const mapMusic = data.allSanityMusic.edges.map(({ node: music }) => (
      <div key={music.id} className="music__wrapper">
        <iframe
          className="iframe__video--music"
          src={music.iframe}
          seamless
          title={music.title}
        />
      </div>
    ))
    return (
      <PageTransition
        defaultStyle={{
          transition: `opacity 1500ms ease-in-out`,
          opacity: 0,
        }}
        transitionStyles={{
          entering: { opacity: 0 },
          entered: { opacity: 1 },
        }}
        transitionTime={100}
      >
        <Layout location={this.props.location}>
          <SEO title="Music" />

          <div id="music">
            <div id="music__box">{mapMusic}</div>
          </div>
        </Layout>
      </PageTransition>
    )
  }
}
